import { Icon } from 'src/components/ui-components/Icon';
import { useSideMenuDispatch } from 'src/stores/SideMenuStore';
import { useMobileNavigationStore } from 'src/stores/MobileNavigationStore';
import MobileNavTreeToggle from '../MobileNavTreeToggle';
import styles from '../../Navigation.module.scss';

export const ProcessNav = () => {
  const mobileNavigationState = useMobileNavigationStore();
  const mobileNavIsOpen = mobileNavigationState?.isMobileNavigationOpen;
  const drawerDispatch = useSideMenuDispatch();

  return (
    <li className={styles.ParentItem}>
      <span
        className={`${styles.ParentLink} ${styles.ParentLink__hasIcon} ${styles.ParentLinkHasSubNav}`}
      >
        <Icon iconName="refresh" aria-hidden="true" size="large" />
        <span className={styles.ParentLinkText}>Processes</span>
      </span>

      <MobileNavTreeToggle
        id="processes-subnav"
        name="Processes"
        tabIndex={mobileNavIsOpen ? 0 : -1}
      />

      <ul id="processes-subnav" className={styles.ChildList}>
        <li className={styles.ChildItem}>
          <button
            type="button"
            className={styles.ChildLink}
            onClick={() => drawerDispatch && drawerDispatch({ type: 'NEW_PROJECT' })}
          >
            <span className={styles.ParentLinkText}>Process 1</span>
          </button>
        </li>

        <li className={styles.ChildItem}>
          <button
            type="button"
            className={styles.ChildLink}
            onClick={() => drawerDispatch && drawerDispatch({ type: 'NEW_PROJECT' })}
          >
            <span className={styles.ParentLinkText}>Process 2</span>
          </button>
        </li>

        <li className={`${styles.ChildItem} ${styles.ChildItem__hasBorderTop}`}>
          <button
            type="button"
            className={`${styles.ChildLink} ${styles.ChildLink__hasIcon}`}
            onClick={() => drawerDispatch && drawerDispatch({ type: 'NEW_PROJECT' })}
          >
            <Icon iconName="plus" aria-hidden="true" />
            <span className={styles.ParentLinkText}>Add process</span>
          </button>
        </li>
      </ul>
    </li>
  );
};
