import { FC, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import cx from 'classnames';
import { ThemeColors } from 'src/assets/variables/colors';

import { useSideMenuStore } from 'src/stores/SideMenuStore';
import { HeaderWithNavigation } from 'src/components/Header/Header';
import { ProcessDrawer } from 'src/components/ProcessDrawer';
import AppShell from 'src/components/AppShell';

import styles from './LayoutPage.module.scss';

import { useIframeEvent } from './LayoutPageHookHelper';

interface LayoutPageProps {
  title?: string;
  theme?: ThemeColors;
}

export const LayoutPage: FC<LayoutPageProps> = ({ title, theme = 'ThemePink', ...props }) => {
  const mainContentRef = useRef<HTMLDivElement>(null);
  const drawerState = useSideMenuStore();

  useIframeEvent();

  return (
    <AppShell title={title} mainRefElement={mainContentRef}>
      <HeaderWithNavigation />

      {/* The main layout will always hav a processes sidebar */}
      {drawerState?.isSideMenuOpen && <ProcessDrawer />}

      <main
        ref={mainContentRef}
        className={cx(
          styles.Content,
          {
            [styles.DrawerIsPinned]: drawerState?.isSideMenuPinned,
          },
          // The theme should be set on the content as other content is global = pink
          theme,
        )}
        data-automation-id="LayoutPageMainContainer"
        {...props}
      >
        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />
      </main>
    </AppShell>
  );
};
