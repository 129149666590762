import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import {
  ButtonPrimary,
  ButtonSecondary,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
} from 'src/components/ui-components';
import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  MIGRATION_STATUS_KEY,
  RP_EMPLOYEE_COUNT_KEY,
  RP_PROJECT_COUNT_KEY,
  TLP_EMPLOYEE_COUNT_KEY,
  TLP_PROJECT_COUNT_KEY,
  useGetMigrationStatus,
  useGetRpEmployeeCount,
  useGetRpProjectCount,
  useGetRPSignalRConfig,
  useGetTlpEmployeeCount,
  useGetTlpProjectCount,
  usePostCompleteProjectMigration,
  usePostCompleteEmployeeMigration,
  usePostImportAllEmployee,
  useGetTlpPlanCount,
  useGetRpPlanCount,
  usePostCompletePlanMigration,
  usePostImportResourcePlans,
} from 'src/apis/resourcePlannerAPI';
import { MuiAlert, MuiStack, Typography } from '@timelog/ui-library';
import { OpenInNew } from '@mui/icons-material';
import styles from './InitialSetup.module.scss';

export const InitialSetup = () => {
  const { t } = useTranslation('resourcePlanner');
  const {
    canTriggerMigration,
    isResourceMigrated,
    isProjectMigrated,
    isResourcePlanMigrated,
    canAddResourcePlan,
  } = useGetMigrationStatus();
  const { count: tlpEmployeeCount } = useGetTlpEmployeeCount();
  const { count: rpEmployeeCount } = useGetRpEmployeeCount(!isResourceMigrated);
  const { count: rpProjectCount } = useGetRpProjectCount(isResourceMigrated && !isProjectMigrated);
  const { count: tlpProjectCount } = useGetTlpProjectCount(
    isResourceMigrated && !isProjectMigrated,
  );
  const { count: rpPlanCount } = useGetRpPlanCount(
    isResourceMigrated && isProjectMigrated && !isResourcePlanMigrated,
  );
  const { count: tlpPlanCount } = useGetTlpPlanCount(
    isResourceMigrated && isProjectMigrated && !isResourcePlanMigrated,
  );
  const { mutate: startMigrateEmployee } = usePostImportAllEmployee();
  const { mutate: startAddResourcePlans } = usePostImportResourcePlans();
  const { mutate: completeEmployeeMigration } = usePostCompleteEmployeeMigration();
  const { mutate: completeProjectMigration, isSuccess: isCompleteProjectMigrationSuccess } =
    usePostCompleteProjectMigration();
  const { mutate: completePlanMigration, isSuccess: isCompletePlanMigrationSuccess } =
    usePostCompletePlanMigration();
  const { tenantId, serviceUrl } = useGetRPSignalRConfig();
  const qc = useQueryClient();

  const showAddEmployeeProgress =
    !isResourceMigrated &&
    !isProjectMigrated &&
    !isResourcePlanMigrated &&
    rpEmployeeCount < tlpEmployeeCount;

  const showAddEmployeeCompleted =
    !isResourceMigrated &&
    rpEmployeeCount >= tlpEmployeeCount &&
    !isProjectMigrated &&
    !isResourcePlanMigrated;

  const showAddProjectProgress =
    isResourceMigrated &&
    !isProjectMigrated &&
    !isResourcePlanMigrated &&
    (rpProjectCount === 0 || rpProjectCount < tlpProjectCount);

  const showAddProjectCompleted =
    isResourceMigrated &&
    !isProjectMigrated &&
    !isResourcePlanMigrated &&
    rpProjectCount > 0 &&
    rpProjectCount >= tlpProjectCount;

  const canShowAddResourcePlanProgress = canAddResourcePlan && tlpPlanCount > 0;

  const showAddResourcePlanProgress =
    isResourceMigrated &&
    isProjectMigrated &&
    !isResourcePlanMigrated &&
    (rpPlanCount === 0 || rpPlanCount < tlpPlanCount);

  const showAddResourcePlanCompleted =
    isResourceMigrated &&
    isProjectMigrated &&
    !isResourcePlanMigrated &&
    rpPlanCount > 0 &&
    rpPlanCount >= tlpPlanCount;

  useEffect(() => {
    if (canTriggerMigration && rpEmployeeCount === 0) {
      startMigrateEmployee();
    }
  }, [canTriggerMigration, rpEmployeeCount, startMigrateEmployee]);

  useEffect(() => {
    if (isCompleteProjectMigrationSuccess || isCompletePlanMigrationSuccess) {
      window.location.reload();
    }
  }, [isCompleteProjectMigrationSuccess, isCompletePlanMigrationSuccess]);

  useEffect(() => {
    (async () => {
      if (serviceUrl) {
        try {
          const hubConnection = new HubConnectionBuilder()
            .withUrl(serviceUrl)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

          // Starts the SignalR connection
          await hubConnection.start();

          // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
          if (hubConnection.connectionId) {
            hubConnection.invoke('SubscribeToTenantId', tenantId);
          }

          hubConnection.on('ReloadMigrationStatus', () => {
            qc.invalidateQueries([MIGRATION_STATUS_KEY]);
          });

          hubConnection.on('ReloadEmployeeCount', () => {
            qc.invalidateQueries([RP_EMPLOYEE_COUNT_KEY]);
            qc.invalidateQueries([TLP_EMPLOYEE_COUNT_KEY]);
          });

          hubConnection.on('ReloadProjectCount', () => {
            qc.invalidateQueries([RP_PROJECT_COUNT_KEY]);
            qc.invalidateQueries([TLP_PROJECT_COUNT_KEY]);
          });
        } catch {
          // ignore singalr connection error
        }
      }
    })();
  }, [serviceUrl, tenantId, qc]);

  return (
    <>
      <MuiAlert
        severity="info"
        action={
          <Link
            target="_blank"
            href={`${process.env.PUBLIC_URL}/downloads/set-up-the-resource-planner.pdf`}
            rel="noreferrer"
          >
            <MuiStack spacing={1} direction="row" alignItems="center">
              <Typography fontWeight={500}>Download manual</Typography>
              <OpenInNew fontSize="small" />
            </MuiStack>
          </Link>
        }
      >
        {t('DownloadManualText')}
      </MuiAlert>
      <div className={styles.wrapper}>
        <ol className={styles.progressSteps}>
          <li className={cx(styles.progressStepsItem, styles.isCurrent)}>
            {t('ImportProgressStepEmployees')}
          </li>
          <li
            className={cx(styles.progressStepsItem, {
              [styles.isCurrent]: isResourceMigrated || showAddEmployeeCompleted,
            })}
          >
            {t('ImportProgressStepProjects')}
          </li>
          {canAddResourcePlan && (
            <li
              className={cx(styles.progressStepsItem, {
                [styles.isCurrent]: isProjectMigrated || showAddProjectCompleted,
              })}
            >
              {t('ImportProgressStepResourcePlan')}
            </li>
          )}
          <li
            className={cx(styles.progressStepsItem, {
              [styles.isCurrent]:
                (canShowAddResourcePlanProgress && showAddResourcePlanCompleted) ||
                (!canShowAddResourcePlanProgress && showAddProjectCompleted),
            })}
          >
            {t('ImportProgressStepDone')}
          </li>
        </ol>

        <div className={styles.intro}>
          <div className={styles.introContent}>
            {showAddEmployeeProgress && (
              <Stack verticalMargin="xLarge">
                <Heading level={2} as={1} bold>
                  {t('ImportEmployeesProgressHeading')}
                </Heading>
                <Stack verticalMargin="large">
                  <Text bold>
                    {t('ImportEmployeesProgress', {
                      completed: rpEmployeeCount,
                      total: tlpEmployeeCount,
                    })}
                  </Text>
                  <Text>{t('ImportEmployeesProgressText', { total: tlpEmployeeCount })}</Text>
                  <Text>{t('ImportEmployeesProgressThanksForPatience')}</Text>
                </Stack>
              </Stack>
            )}

            {showAddEmployeeCompleted && (
              <Stack verticalMargin="xLarge">
                <Heading level={2} as={1} bold>
                  {t('ImportEmployeesProgressCompletedHeading')}
                </Heading>
                <Stack verticalMargin="large">
                  <Text>{t('ImportEmployeesProgressCompleted')}</Text>
                </Stack>
                <ButtonPrimary
                  data-automation-id="ImportProjectsButton"
                  onClick={() => completeEmployeeMigration()}
                >
                  {t('ImportProjectsButtonText')}
                </ButtonPrimary>
              </Stack>
            )}

            {showAddProjectProgress && (
              <Stack verticalMargin="xLarge">
                <Heading level={2} as={1} bold>
                  {t('ImportProjectsProgressHeading')}
                </Heading>
                <Stack verticalMargin="large">
                  <Text bold>
                    {t('ImportProjectsProgress', {
                      completed: rpProjectCount,
                      total: tlpProjectCount,
                    })}
                  </Text>
                  <Text>{t('ImportProjectsProgressText', { total: tlpProjectCount })}</Text>
                  <Text>{t('ImportProjectsProgressThanksForPatience')}</Text>
                </Stack>
              </Stack>
            )}

            {!canShowAddResourcePlanProgress && showAddProjectCompleted && (
              <Stack verticalMargin="xLarge">
                <Heading level={2} as={1} bold>
                  {t('ImportProjectsProgressCompletedHeading')}
                </Heading>
                <Stack verticalMargin="large">
                  <Text>{t('ImportProjectsProgressCompleted')}</Text>
                </Stack>
                <ButtonPrimary
                  data-automation-id="ImportProjectsCompletedButton"
                  onClick={() => {
                    completeProjectMigration();
                    completePlanMigration();
                  }}
                >
                  {t('ImportProjectsCompletedButtonText')}
                </ButtonPrimary>
              </Stack>
            )}

            {canShowAddResourcePlanProgress && showAddProjectCompleted && (
              <Stack verticalMargin="xLarge">
                <Heading level={2} as={1} bold>
                  {t('ImportProjectsProgressCompletedHeading')}
                </Heading>
                <Stack verticalMargin="large">
                  <Text>{t('ImportProjectsProgressCompleted')}</Text>
                </Stack>
                <Stack verticalMargin="large">
                  <Text>{t('ImportProjectsProgressCompletedAddResourcePlans')}</Text>
                </Stack>
                <Flex>
                  <ButtonSecondary
                    data-automation-id="SkipImportResourcePlanButton"
                    onClick={() => {
                      completeProjectMigration();
                      completePlanMigration();
                    }}
                  >
                    {t('ImportProjectsSkipButtonText')}
                  </ButtonSecondary>
                  <ButtonPrimary
                    data-automation-id="ImportResourcePlanButton"
                    onClick={() => {
                      completeProjectMigration();
                      startAddResourcePlans();
                    }}
                  >
                    {t('ImportProjectsAddResourcePlansButtonText')}
                  </ButtonPrimary>
                </Flex>
              </Stack>
            )}

            {showAddResourcePlanProgress && (
              <Stack verticalMargin="xLarge">
                <Heading level={2} as={1} bold>
                  {t('ImportPlanProgressHeading')}
                </Heading>
                <Stack verticalMargin="large">
                  <Text bold>
                    {t('ImportPlanProgress', {
                      completed: rpPlanCount,
                      total: tlpPlanCount,
                    })}
                  </Text>
                  <Text>{t('ImportPlanProgressText', { total: tlpPlanCount })}</Text>
                  <Text>{t('ImportPlanProgressThanksForPatience')}</Text>
                </Stack>
              </Stack>
            )}

            {showAddResourcePlanCompleted && (
              <Stack verticalMargin="xLarge">
                <Heading level={2} as={1} bold>
                  {t('ImportPlanProgressCompletedHeading')}
                </Heading>
                <Stack verticalMargin="large">
                  <Text>{t('ImportPlanProgressCompleted')}</Text>
                </Stack>
                <ButtonPrimary
                  data-automation-id="ImportPlanCompletedButton"
                  onClick={() => completePlanMigration()}
                >
                  {t('ImportPlanCompletedButtonText')}
                </ButtonPrimary>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
