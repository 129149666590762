import { FC, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import cx from 'classnames';
import {
  Accordion as ReachAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionProps as ReachAccordionProps,
  AccordionItemProps as ReachAccordionItemProps,
  AccordionButtonProps,
  AccordionPanelProps as ReachAccordionPanelProps,
  useAccordionItemContext,
} from '@reach/accordion';
import { Heading, HeadingProps } from 'src/components/ui-components/Heading';
import '@reach/accordion/styles.css';
import { Flex } from 'src/components/ui-components/Flex';
import { Icon } from 'src/components/ui-components/Icon';
import styles from './Accordion.module.scss';

interface AccordionInterface {
  (props: AccordionProps): JSX.Element;
  Item: FC<AccordionItemProps>;
  Trigger: ForwardRefExoticComponent<AccordionTriggerInterface & RefAttributes<HTMLButtonElement>>;
  Panel: FC<AccordionPanelProps>;
}

interface AccordionProps extends ReachAccordionProps {
  className?: string;
}

export const Accordion: AccordionInterface = (props) => (
  <ReachAccordion className={styles.wrapper} {...props} />
);

interface AccordionItemProps extends ReachAccordionItemProps {
  className?: string;
}

Accordion.Item = (props) => <AccordionItem {...props} />;

interface AccordionTriggerInterface extends AccordionButtonProps, Pick<HeadingProps, 'level'> {
  className?: string;
}

Accordion.Trigger = forwardRef(({ level, children, ...props }, ref) => {
  const { isExpanded } = useAccordionItemContext();
  return (
    <Heading level={level} as={4} bold>
      <AccordionButton type="button" className={styles.button} ref={ref} {...props}>
        <Flex as="span" horizontalAlignment="justify">
          <Flex.Item as="span">{children}</Flex.Item>
          <span className={styles.icon}>
            <Icon iconName={isExpanded ? 'pointUp' : 'pointDown'} />
          </span>
        </Flex>
      </AccordionButton>
    </Heading>
  );
});

interface AccordionPanelProps extends ReachAccordionPanelProps {
  className?: string;
}

Accordion.Panel = ({ className, ...props }: AccordionPanelProps) => (
  <AccordionPanel className={cx(styles.panel, className)} {...props} />
);
